body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-header .btn-close {
  color: #fff !important;
}

.slideshow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  height: 50vh;
}

/* .slide {
  flex: 0 0 100%;
  width: 100%;
  min-width: 100%;
  scroll-snap-align: start;
} */

.carousel {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.slide {
  flex: 0 0 100%;
  /* background-color: aqua; */
  scroll-snap-align: center;
}

/* .slide.active {
} */
